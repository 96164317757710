import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";
import Select from "react-select";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { LinearProgress, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import CusPopover from "../components/Popover";
import MyDatePicker from "../components/DatePicker";
import dayjs from "dayjs";
import api from "../hooks/apiClient";

import PrintTemplate1 from "../printTemplates/Template2";
const OrdersBooking = () => {
  const { store_id } = useParams();
  const printRef = useRef(); // Ref to capture all PrintTemplate1 components
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("1");
  const [cities, setCities] = useState([]);
  const [order, setOrder] = useState(null);
  const [orderCount, setOrderCount] = useState(null);
  const [data, setData] = useState([]);
  const [resData, setResData] = useState([]);
  const [flatData, setFlatData] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [loading, setLoading] = useState(true);
  const [tagLoading, setTagLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [orderAddress, setOrderAddress] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [response, setResponse] = useState("");
  const [orderP, setOrderP] = useState("");
  const [progress, setProgress] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const ToggleOpen = () => {
    setOpen(!open);
  };
  const handleSelectChange = (option) => {
    setSelectedCity(option);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);

    getOrdersForPrint(date);
  };

  useEffect(() => {
    setValue("1");
    const processingJobId = localStorage.getItem("processingJobId");
    if (processingJobId) {
      setIsProcessing(true);
      pollJobStatus(processingJobId);
    }
    getOrdersAndCites();
  }, [store_id]);
  const getOrdersAndCites = async () => {
    try {
      const response = await api.get(`orders/shopify/${store_id}`);
      const res = await api.get("couriers/allcities");

      setFlatData(response.data);
      setData(response.data);

      const data = res.data.map((item) => ({
        value: item.name,
        label: `${item.name}   - ${item.courier_name}`,
      }));
      setCities(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching Orders And city:", error);
    }
  };
  const getOrdersForPrint = async (date) => {
    const dateStart = dayjs(date).startOf("day").toISOString(); // Start of the day (00:00:00)
    const dateEnd = dayjs(date).endOf("day").toISOString(); // End of the day (23:59:59)

    setLoading(true);
    try {
      const response = await api.get(`orders/print/${store_id}`, {
        params: { dateStart: dateStart, dateEnd: dateEnd },
      });

      setData(response.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching Orders And city:", error);
    }
  };
  const btnBooking = async () => {
    if (selectedRows.length == 0 || isProcessing) {
      setSnackbar({
        children: "Orders cannot be empty 0r is Processing..",
        severity: "warning",
      });

      return;
    }

    try {
      const first50Rows = selectedRows.slice(0, 50);
      const orderIds = first50Rows.map((order) => order.id).join(",");
      setLoading(true);
      setIsProcessing(true);
      const res = await api.post(`orders/booking/${store_id}`, {
        order_ids: orderIds,
      });
      setOrderCount(res.data.orderCount);
      setLoading(false);
      setSelectedRows([]);
      setResponse("Orders processing initiated. Please wait...");
      pollJobStatus(res.data.jobId); // Start polling for job status
      localStorage.setItem("processingJobId", res.data.jobId);
    } catch (error) {
      setSelectedRows([]);
      setResponse("Error: " + error.message);
      setLoading(false);
      setIsProcessing(false);
    }
  };
  const pollJobStatus = async (jobId) => {
    try {
      const res = await api.get(`orders/booked/${jobId}`);

      if (res.data.status === "completed") {
        setResponse("Orders processing completed...");
        setProgress(100);
        setResData(res.data.results);
        setOrderP(res.data.orderPro);
        setIsProcessing(false);
        await getOrdersAndCites(store_id);
        localStorage.removeItem("processingJobId");
        setSnackbar({
          children: "Order completed successfully!",
          severity: "success",
        });
      } else if (res.data.status === "processing") {
        setIsProcessing(true);
        setResData(res.data.results);
        setOrderP(res.data.orderPro);
        setProgress(res.data.progress);
        setTimeout(() => {
          pollJobStatus(jobId);
        }, 5000);
      } else {
        setResponse("Orders processing ." + res.data.status);
        setIsProcessing(false);
        localStorage.removeItem("processingJobId");
      }
    } catch (error) {
      console.error("Error polling job status:", error.message);
      setResponse("Error: " + error.message);
      setIsProcessing(false); // Reset processing flag in case of error
      localStorage.removeItem("processingJobId");
    }
  };
  const handleEditClick = (id) => async () => {
    try {
      const selectedOrder = data.find((item) => item.id === id);
      if (selectedOrder) {
        setOrder(selectedOrder);
        setSelectedCity(null);
        setOrderAddress(selectedOrder.shipping_address);
        ToggleOpen();
      }
    } catch (error) {
      console.error("Error selecting order:", error);
    }
  };

  const orderUpdate = async () => {
    try {
      if (!order || !selectedCity || !orderAddress) {
        return setSnackbar({
          children: "Order Cannot be empaty!",
          severity: "error",
        });
      }
      await api.put(
        "orders/update",
        { order, selectedCity, orderAddress, store_id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setSnackbar({
        children: "Order updated successfully!",
        severity: "success",
      });
      ToggleOpen();
    } catch (error) {
      console.error("update failed:", error);
    }
  };
  const columns = [
    // { field: "id", headerName: "ID", width: 50 },
    { field: "name", headerName: "Order#", width: 150 },
    {
      field: "financial_status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        if (params.value === "paid") {
          return (
            <button className="btn btn-sm btn-success">{params.value}</button>
          );
        } else if (params.value === "unpaid") {
          return <span className="btn btn-sm btn-warning">{params.value}</span>;
        } else if (params.value === "pending") {
          return (
            <span className="magic-button magic-button-green">
              {params.value}
            </span>
          );
        } else {
          return (
            <span className="btn btn-sm btn-secondary">{params.value}</span>
          );
        }
      },
    },
    {
      field: "fulfillment_status",
      headerName: "Fulfillment",
      width: 100,

      renderCell: (params) =>
        params.value ? (
          <button className="btn btn-sm btn-success">
            {params.row.fulfillment_status}
          </button>
        ) : (
          <span className="magic-button magic-button-yellow">
            Unfulfiled{params.value}
          </span>
        ),
    },
    {
      field: "total_price",
      headerName: "Price",
      width: 100,
    },
    {
      field: "total_outstanding",
      headerName: "O Price",
      width: 100,
    },
    { field: "shipping_name", headerName: "Name", width: 200 },
    { field: "shipping_city", headerName: "City", width: 150 },
    { field: "shipping_phone", headerName: "Mobile", width: 150 },
    { field: "shipping_address", headerName: "Address", width: 300 },
    { field: "products", headerName: "Products", width: 100 },
    {
      field: "note",
      headerName: "Note",
      width: 100,
      renderCell: (params) => <CusPopover cellValue={params.value} />,
    },
    { field: "tags", headerName: "Tags", width: 250 },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,

      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const columns1 = [
    // { field: "id", headerName: "ID", width: 50 },
    { field: "name", headerName: "Order#", width: 150 },
    {
      field: "financial_status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        if (params.value === "paid") {
          return (
            <button className="btn btn-sm btn-success">{params.value}</button>
          );
        } else if (params.value === "unpaid") {
          return <span className="btn btn-sm btn-warning">{params.value}</span>;
        } else if (params.value === "pending") {
          return (
            <span className="magic-button magic-button-green">
              {params.value}
            </span>
          );
        } else {
          return (
            <span className="btn btn-sm btn-secondary">{params.value}</span>
          );
        }
      },
    },
    {
      field: "fulfillment_status",
      headerName: "Fulfillment",
      width: 100,

      renderCell: (params) =>
        params.value ? (
          <button className="btn btn-sm btn-success">
            {params.row.fulfillment_status}
          </button>
        ) : (
          <span className="magic-button magic-button-yellow">
            Unfulfiled{params.value}
          </span>
        ),
    },
    {
      field: "total_price",
      headerName: "Price",
      width: 100,
    },
    {
      field: "total_outstanding",
      headerName: "O Price",
      width: 100,
    },
    { field: "shipping_name", headerName: "Name", width: 200 },
    { field: "shipping_city", headerName: "City", width: 150 },

    { field: "products", headerName: "Products", width: 50 },

    { field: "tags", headerName: "Tags", width: 400 },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,

      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const handleTabs = (event, newValue) => {
    setValue(newValue);
    if (newValue === "2") {
      const filteredOrders = flatData.filter((order) => {
        const tags = order.tags.split(", ").map((tag) => tag.trim());
        const hasRequiredTags = ["Call Confirmed", "Confirmed"].some((tag) =>
          tags.includes(tag)
        );

        const hasExcludedTags = ["Hold", "OrderCount"].some((tag) =>
          tags.includes(tag)
        );
        return hasRequiredTags && !hasExcludedTags;
      });
      setData(filteredOrders);
    } else if (newValue === "1") {
      setData(flatData);
    } else if (newValue === "3") {
      getOrdersForPrint();
    }
  };

  const orderData = selectedRows.map((order) => ({
    id: order.id,
    tags: order.tags,
  }));
  const handleTagRemove = async (packed) => {
    setTagLoading(packed);

    if (orderData.length === 0) return;
    try {
      const res = await api.post(`orders/tags/${store_id}`, {
        orders: orderData,
        packed: packed,
      });
      setTagLoading(false);
      setShow(false);
      console.log("Tags updated:", res.data);
    } catch (error) {
      setTagLoading(false);
      console.error("Error updating tags or printing:", error);
    }
  };
  async function handleClick() {
    setShow((prevShow) => !prevShow);
  }

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Orders",

    onAfterPrint: () => {
      handleTagRemove(true);
    },
  });
  // const sortedOrders = selectedRows.sort((a, b) => {
  //   const lastFulfillmentA =
  //     a.fulfillments && a.fulfillments.length > 0
  //       ? a.fulfillments[a.fulfillments.length - 1].tracking_company
  //       : "";

  //   const lastFulfillmentB =
  //     b.fulfillments && b.fulfillments.length > 0
  //       ? b.fulfillments[b.fulfillments.length - 1].tracking_company
  //       : "";

  //   return lastFulfillmentA.localeCompare(lastFulfillmentB);
  // });

  if (show) {
    if (tagLoading) {
      return (
        <div className="loading">
          <CircularProgress /> Loading ...
        </div>
      );
    }

    return (
      <div className="container">
        <div className="shadow p-1 bg-body rounded floating-button-top">
          <button className="btn btn-info no-print " onClick={handleClick}>
            Orders
          </button>{" "}
          <button className="ms-2 btn btn-sm btn-success" onClick={handlePrint}>
            Print Selected Orders
          </button>
          <button
            className="ms-2 btn btn-sm btn-success"
            onClick={() => handleTagRemove(true)}
          >
            Remove Tag
          </button>
        </div>

        <div ref={printRef}>
          {selectedRows &&
            selectedRows.map((order, index) => (
              <div key={index}>
                <PrintTemplate1 order={order} />
              </div>
            ))}
        </div>
      </div>
    );
  }
  return (
    <div>
      <h6 className=" shadow p-3 mb-1 bg-body rounded">Orders for booking</h6>
      <div className="shadow p-1 bg-body rounded floating-button-top">
        {value === "3" && selectedRows.length > 0 && (
          <button className="btn btn-sm btn-outline-info" onClick={handleClick}>
            Print Selected Orders
          </button>
        )}
      </div>

      <Dialog
        open={open}
        keepMounted
        onClose={() => ToggleOpen()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Edit Order"} </DialogTitle>
        <DialogContent>
          <p>{open && order.name}</p>
          <p>{open && order.shipping_address}</p>

          <p>{open && order.shipping_phone}</p>
          <div className="">
            <Select
              value={selectedCity}
              isLoading={loading}
              isSearchable={true}
              name="City"
              options={cities}
              onChange={handleSelectChange}
            />
            <p>{open && order.shipping_city}</p>
            <label htmlFor="order_name" className="form-label ml-2">
              Order Adress
              <textarea
                rows="5"
                cols="33"
                className="form-control"
                name="order_address"
                value={orderAddress}
                onChange={(e) => setOrderAddress(e.target.value)}
              />
            </label>
          </div>
          <div className="mb-3">
            <button
              className="btn btn-info"
              type="button"
              onClick={orderUpdate}
            >
              Save
            </button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => ToggleOpen()}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar ? true : false}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert {...snackbar} onClose={handleCloseSnackbar} />
      </Snackbar>

      <Tabs value={value} onChange={handleTabs} centered>
        <Tab label="all" value="1" />
        <Tab label="awaiting approval" value="2" />
        <Tab label="Print Orders" value="3" />
      </Tabs>
      {value === "2" && (
        <div className="shadow p-1 bg-body rounded floating-button-top">
          <button
            className="btn btn-sm btn-outline-secondary "
            onClick={btnBooking}
            disabled={isProcessing}
          >
            Tap Here For Booking
          </button>
        </div>
      )}
      {/* <div className="shadow p-1 bg-body rounded floating-button">
        {value === "3" && (
          <MyDatePicker
            selectedDate={selectedDate}
            onDateChange={handleDateChange}
            label="Select Date"
          />
        )}
      </div> */}
      <div className="container">
        {(response || progress) && (
          <div className="shadow p-3 mb-2 bg-body rounded">
            {orderCount && <div>Total Orders {orderCount}</div>}
            {response && <div>{response}</div>}
            {progress && (
              <div>
                {" "}
                <p>Orders: {orderP}</p>
                <LinearProgress
                  value={progress}
                  valueBuffer={progress}
                  variant="buffer"
                />
              </div>
            )}
          </div>
        )}
      </div>

      {resData.length > 0 ? (
        resData.map((item, index) => (
          <div
            key={index}
            className={`custom-alert ${
              item.status === "failed"
                ? "custom-alert-danger"
                : "custom-alert-success"
            }`}
          >
            <strong>Order:</strong> {item.order},<strong> Status:</strong>{" "}
            {item.status},<strong> Reason:</strong> {item.reason}{" "}
            <strong>{item.booked}</strong>
          </div>
        ))
      ) : (
        <div></div>
      )}
      {!isProcessing && (
        <div style={{ height: 900, width: "100%" }}>
          <DataGrid
            disableRowSelectionOnClick={value === "1"}
            rowHeight={28}
            rows={data}
            columns={value === "3" ? columns1 : columns}
            pageSize={5}
            loading={loading}
            getRowId={(row) => row.id}
            checkboxSelection={value === "2" || value === "3"}
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = data.filter((row) =>
                selectedIDs.has(row.id)
              );
              setSelectedRows(selectedRows);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default OrdersBooking;
