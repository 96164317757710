import React, { useEffect, useState } from "react";

import Select from "../components/Select";

import SelectCourier from "../components/SelectCourier";
import PaidOrders from "./PaidOrders";
import LinearWithValueLabel from "../components/ProgressLine";
import api from "../hooks/apiClient";

const OrdersPay = () => {
  const [showOrders, setShowOrders] = useState(false);
  const [response, setResponse] = useState("");
  const [orderP, setOrderP] = useState("");
  const [progress, setProgress] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [selectedCourierId, setselectedCourierId] = useState("");
  const showToggle = () => {
    setShowOrders(!showOrders);
  };
  const handleSelectChange = (value) => {
    setSelectedStoreId(value);

    if (value) {
      fetchStoresCouriers(value);
    } else {
      setCouriers([]);
    }
  };
  const handleSelectChangeC = (value) => {
    setselectedCourierId(value);
  };
  useEffect(() => {
    const processingJobId = localStorage.getItem("processingJobId");
    if (processingJobId) {
      setIsProcessing(true);
      pollJobStatus(processingJobId);
    }
    api
      .get("stores/all")
      .then((response) => {
        setStores(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const fetchStoresCouriers = async (store_id) => {
    setLoading(true);

    try {
      const couriersResponse = await api.get("couriers/all/bystore", {
        params: {
          store_id: store_id,
        },
      });

      setCouriers(couriersResponse.data);
    } catch (err) {
      console.error("Error fetching stores and couriers:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDivClick = (dataArray, arrayName) => {
    const popupWindow = window.open("", "_blank", "width=600,height=400");

    if (popupWindow) {
      const htmlContent = `
        <div style="font-family: Arial, sans-serif;">
          <h2>${arrayName}</h2>
          <div style="display: flex; flex-wrap: wrap;">
            ${Object.entries(dataArray)
              .map(
                ([key, value]) => `
                <div style="border: 1px solid #ccc; border-radius: 8px; padding: 10px; margin: 10px; width: 230px;  text-align: center;">
                  <h3 style="textalign:center">${key}</h3>
                  ${Object.entries(value)
                    .map(
                      ([subKey, subValue]) =>
                        `<p style="border: 1px solid #ccc; border-radius: 8px; padding: 10px; margin: 10px; width: 200px;"><strong>${subKey}:</strong> ${subValue}</p>`
                    )
                    .join("")}
                </div>
              `
              )
              .join("")}
          </div>
        </div>
      `;
      popupWindow.document.write(htmlContent);
    } else {
      alert(
        "Popup blocked! Please allow popups for this site to see the data."
      );
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!isProcessing) {
      if (selectedFile && selectedStoreId && selectedCourierId) {
        const formData = new FormData();
        formData.append("excelFile", selectedFile);
        formData.append("storeId", selectedStoreId); // Assuming storeId is a variable containing the store ID
        formData.append("courierId", selectedCourierId);

        try {
          setIsProcessing(true);
          const res = await api.post("upload/file", formData);
          setOrderP(res.data.result.orders.length);

          setFileData(res.data.result);
          setResponse("Orders processing initiated. Please wait...");
          pollJobStatus(res.data.jobId); // Start polling for job status
          localStorage.setItem("processingJobId", res.data.jobId);
        } catch (error) {
          console.error("Error processing orders:", error);
          setResponse("Error: " + error.message);
          setIsProcessing(false);
        }
      } else {
        alert("please Select All Fields");
      }
    } else {
      alert("A file upload is already in progress.");
    }
  };
  const pollJobStatus = async (jobId) => {
    try {
      const res = await api.get(`upload/file/${jobId}`);

      if (res.data.status === "completed") {
        setResponse("Orders processing completed.");
        setOrderP(res.data.orderPro);
        setProgress(100);
        setIsProcessing(false);
        localStorage.removeItem("processingJobId");
      } else if (res.data.status === "processing") {
        setIsProcessing(true);
        setOrderP(res.data.orderPro);
        setProgress(res.data.progress);

        setTimeout(() => {
          pollJobStatus(jobId);
        }, 10000);
      } else {
        setResponse("Orders processing ." + res.data.status);
        setIsProcessing(false);
        localStorage.removeItem("processingJobId");
      }
    } catch (error) {
      console.error("Error polling job status:", error.message);
      setResponse("Error: " + error.message);
      setIsProcessing(false); // Reset processing flag in case of error
      localStorage.removeItem("processingJobId");
    }
  };

  async function stopUpload() {
    api
      .post("upload/stop-loop")
      .then((response) => {
        setResponse("");
        setOrderP("");
        setProgress();
        // setSelectedFile(null);
        // setRealTimeData([]);
        setFileData(null);
        setIsProcessing(false);
        setSelectedStoreId("");
        setselectedCourierId("");
        localStorage.removeItem("processingJobId");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  if (loading) {
    return <div>Loading...</div>;
  }
  if (showOrders) {
    return <PaidOrders show={showToggle} />;
  }
  return (
    <div>
      <button
        className="btn btn-sm btn-outline-info me-3  float-end"
        onClick={showToggle}
      >
        Show Orders
      </button>
      <div className="container mt-4">
        {(response || progress) && (
          <div className="container ">
            {response && <div>{response}</div>}
            {progress && (
              <div>
                {" "}
                <span className="magic-button magic-button-green mb-1">
                  Orders: {orderP}
                </span>
                <div className="">
                  <LinearWithValueLabel progress={progress} />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="shadow p-2 mb-1 bg-body rounded d-flex justify-content-around">
          <div className="">
            <Select
              stores={stores}
              value={selectedStoreId}
              onChange={handleSelectChange}
            />
          </div>
          <div className="">
            <SelectCourier
              couriers={couriers}
              value={selectedCourierId}
              onChange={handleSelectChangeC}
            />
          </div>
          <div className="">
            <input
              className="form-control form-control-sm"
              type="file"
              onChange={handleFileSelect}
            />
          </div>

          {!fileData && !isProcessing > 0 && (
            <div className="">
              <div className="">
                <button
                  className="btn btn-info btn-sm"
                  onClick={handleFileUpload}
                >
                  Upload File
                </button>
              </div>
            </div>
          )}
          {(fileData || isProcessing > 0) && (
            <div className="">
              <button className="btn btn-sm btn-danger" onClick={stopUpload}>
                Stop Upload
              </button>
            </div>
          )}
        </div>

        {fileData && (
          <div>
            <div className="d-flex flex-wrap justify-content-around">
              {fileData &&
                Object.keys(fileData).map((arrayName, index) => (
                  <div
                    className="shadow p-3 mb-2 bg-body rounded d-flex  flex-column"
                    key={index}
                    onClick={() =>
                      handleDivClick(fileData[arrayName], arrayName)
                    }
                  >
                    <span className="magic-button magic-button-yellow  mb-1">
                      {arrayName.toUpperCase()}
                    </span>
                    <span className="magic-button ">
                      {" "}
                      {fileData[arrayName].length}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrdersPay;
