import React from "react";

const Select = ({ stores, value, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <select
      className="form-control mb-3 form-control-sm"
      value={value}
      onChange={handleChange}
    >
      <option value="">Select a store</option>
      {stores.map((store) => (
        <option key={store.id} value={store.id}>
          {store.public_name}
        </option>
      ))}
    </select>
  );
};

export default Select;
